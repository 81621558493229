import React from 'react';
import { render } from 'react-dom';
import MarkdownEditor from 'cute-md-editor';
import axios from 'axios';
import pubSubEvents from '../../util/pubsub_event_channels';

// This is used with a standalone post and old JS. It's also used with page content other more
// "Intellum Modern" JS functions. `standalonePost` signifies if used with standalone post or not
export default function initMarkdownEditor(editorEl, standalonePost, showUploadMessage) {
  // This is a terrible hack to get around the fact that the old JS uses a global function
  window.initMarkdownEditor = initMarkdownEditor;

  standalonePost = standalonePost || false;
  showUploadMessage = showUploadMessage || false;

  const signingUrl = editorEl.getAttribute('data-signing-url');
  const previewUrl = editorEl.getAttribute('data-preview-url');
  const authenticityToken = editorEl.getAttribute('data-authenticity-token');
  const content = editorEl.textContent;
  const contentElementId = editorEl.getAttribute('data-content-element-id');
  const contentElementName = editorEl.getAttribute('data-content-element-name');
  const formEl = document.getElementById('activity_form');

  let lastSavedContent = content;
  let contentEl;

  editorEl.textContent = '';

  if (standalonePost) {
    formEl.on('ajax:success', (event, data) => {
      if (!contentEl) {
        contentEl = document.getElementById('course_content');
      }
      lastSavedContent = contentEl.value;
    });

    // Warn with browser default dialog if this is on its own page and the user tries to leave without saving
    window.onbeforeunload = function (event) {
      if (!contentEl) {
        contentEl = document.getElementById('course_content');
      }
      if (lastSavedContent != contentEl.value) {
        // Show the standard (and uncustomizable) browser dialog appears asking the user
        // for confirmation to leave the page.
        event.returnValue = '';
        return '';
      }
    };
  }

  // Warn with custom dialog if this is in a modal and the user tries to close without saving
  // This needs the form to be a child of .modal__content because IE can't handle .closest()
  if (formEl && formEl.parentElement.classList.contains('modal__content')) {
    const modalEventSubscriber = PubSub.subscribe(pubSubEvents.modal_close, (msg, eventData) => {
      if (msg === pubSubEvents.modal_close) {
        if (!contentEl) {
          contentEl = document.getElementById('course_content');
        }
        PubSub.publish(pubSubEvents.form_change, {
          hasChanged: lastSavedContent != contentEl.value,
          formId: 'activity_form',
        });
      }
    });
  }

  render(
    <MarkdownEditor
      toolbarOptions={[
        'bold',
        'link',
        'headers',
        'italic',
        'quote',
        'unordered-list',
        'ordered-list',
      ]}
      elementId={contentElementId}
      elementName={contentElementName}
      content={content}
      previewClass="postcontent"
      showUploadedFiles={false}
      showUploadMessage={showUploadMessage}
      markdownGuideUrl="https://experience.intellum.com/student/page/454110"
      converter={(markdown) =>
        new Promise((resolve, reject) => {
          axios
            .post(previewUrl, { markdown, authenticity_token: authenticityToken })
            .then((response) => {
              resolve(response.data);
            })
            .catch((err) => reject(err));
        })
      }
      onFileUpload={(files, callback) =>
        new Promise((resolve, reject) => {
          const file = files[0];

          if (file.type.split('/')[0] != 'image') {
            reject('Your file must be an image.');
            return;
          }
          if (file.size > 200 * 1024 * 1024) {
            reject('Your file must be smaller than 200Mb.');
            return;
          }

          axios
            .get(signingUrl, { params: { name: file.name, content_type: file.type } })
            .then((response) => {
              const options = {
                headers: {
                  'Content-Type': file.type,
                },
                onUploadProgress(progressEvent) {
                  if (progressEvent.lengthComputable == true) {
                    const progress = Math.round((100 * progressEvent.loaded) / progressEvent.total);
                    callback(progress);
                  }
                },
              };
              axios
                .put(response.data.put, file, options)
                .then((putResponse) => {
                  resolve(response.data.get);
                })
                .catch((err) => reject(err));
            })
            .catch((err) => reject(err));
        })
      }
    />,
    editorEl,
  );
}
