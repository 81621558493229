export default {
  dependency: 'dependency',
  dependency_disable: 'dependency.disable',
  dependency_enable: 'dependency.enable',
  dependency_toggle: 'dependency.toggle',
  dependency_based_on_value: 'dependency.value',
  filters: 'filters',
  filters_block: 'filters.block',
  filters_counter: 'filters.counter',
  filters_tags: 'filters.tags',
  filters_update: 'filters.update',
  form: 'form',
  form_change: 'form.change',
  form_revert: 'form.revert',
  infinitescroll: 'infinitescroll',
  infinitescroll_loaded: 'infinitescroll.loaded',
  modal: 'modal',
  modal_open: 'modal.open',
  modal_close: 'modal.close',
  modal_destroy: 'modal.destroy',
  modal_update: 'modal.update',
  notifications_change: 'notifications.change',
  page: 'page',
  page_addalttext: 'page.addalttext',                // alt text has been added to an image
  page_addfiledialog: 'page.addfiledialog',          // create url and take user to URL editor
  page_addhtmlpost: 'page.addhtmlpost',              // create html post and take user to HTML editor
  page_addpost: 'page.addpost',                      // set up the post for create
  page_cancelinsert: 'page.cancelinsert',            // an instruction to cancel an insert
  page_componentcreated: 'page.componentcreated',    // a new component has been created (but still needs to be added to ui)
  page_componentupdated: 'page.componentupdated',    // an existing component has been updated
  page_componentselected: 'page.componentselected',  // component is selected
  page_deselectcomponent: 'page.deselectcomponent',  // deselect component
  page_disableadd: 'page.disableadd',                // disable Add button
  page_enableadd: 'page.enableadd',                  // re-enable Add button
  page_filechange: 'page.filechange',                // a file change (e.g. display type or upload finished)
  page_fileadded: 'page.fileadded',                  // when a file has finished being added (and saved)
  page_handleaddhtmlpost: 'page.handleaddhtmlpost',  // when user has selected add html post - have add-component handle it
  page_handleaddfile: 'page.handleaddfile',          // when user has selected add file - have add-component handle it
  page_handleaddimage: 'page.handleaddimage',        // when user has selected add image - have add-component handle it
  page_handleaddpost: 'page.handleaddpost',          // when user has selected add post - have add-component handle it
  page_handleaddvideo: 'page.handleaddvideo',        // when user has selected add video - have add-component handle it
  page_handleaddurl: 'page.handleaddurl',            // when user has selected add url - have add-component handle it
  page_postcreated: 'page.postcreated',              // when a new post component is created
  page_changesaved: 'page.saved',                    // when a component in the page has been saved
  page_changeunsaved: 'page.unsaved',                // when a component on the page has an unsaved change
  page_setpostfocus: 'page.setpostfocus',            // an instruction to set the focus of the post
  page_selectcomponent: 'page.selectcomponent',      // an instruction to select a component
  page_selectsibling: 'page.selectsibling',          // an instruction to select a sibling component
  page_setupinsert: 'page.setupinsert',              // an instruction to setup the insert ui
  page_removesplitcontent: 'page.removesplitcontent',// tell a post to safely remove content that has been split out
  page_urladded: 'page.urladded',                    // when a url has finished being added (and saved)
  rebind: 'rebind',
  rebind_formtriggers: 'rebind.formtriggers',
  remove: 'remove',
  remove_item: 'remove.item',                        // instruct exceed-remove-item to do a remove
  remove_start: 'remove.start',                      // published by exceed-remove-item when a remove starts
  remove_success: 'remove.success',                  // published by exceed-remove-item when a remove is successful
  select: 'select',
  select_change: 'select.change',
  sortable: 'sortable',
  sortable_choose: 'sortable.choose',
  sortable_error: 'sortable.error',
  sortable_start: 'sortable.start',
  sortable_success: 'sortable.success',
  update: 'update',
  update_content: 'update.content',
  uploadfile: 'uploadfile',
  uploadfile_added: 'uploadfile.added',
  uploadfile_change: 'uploadfile.change',
  uploadfile_delete: 'uploadfile.delete',
  uploadfile_error: 'uploadfile.error',
  uploadfile_processing: 'uploadfile.processing',
  uploadfile_start: 'uploadfile.start',
  youtube_loaded: 'youtube.loaded'
}
